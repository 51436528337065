cs.ns("app.ui.trait.root.modaldialogs");
app.ui.trait.root.modaldialogs.view = cs.trait({
    protos: {

        render: function () {
            this.base();
            cs(this).socket({
                name:   "modalDialog",
                ctx:    this.ui,
                spool:  cs(this).state(),
                plug:   this.modalDialogPlug.bind(this),
                unplug: this.modalDialogUnplug.bind(this)
            });

        },

        modalDialogPlug (el/*, comp*/) {
            if (cs(this).value("global:state:modalDialogVisible")) {
                console.error({ message: `View socket 'modalDialog' denies incoming plug: There is already a modal dialog open. Can not open a second modal dialog.`})
            } else {
                cs(this).value("global:state:modalDialogVisible", true);
                $("body").append(el);
            }
        },

        modalDialogUnplug (el/*, comp*/) {
            cs(this).value("global:state:modalDialogVisible", false);
            $(el).remove();
        }
    }
});